<template>
    <section>
          <documents-list v-for="item in content"
                          :key="`list${item.id}`"
                          class="documents__list"
                          :doc="{
                            ...item,
                            name: item.title,
                            url: item.link
                          }"
          />
    </section>
</template>
<script>
import DocumentsList from '@/components/ChamberNormativeBaseDocuments'

export default {
  name: 'Documents',
  props: {
    content: {
      type: Array,
      required: true,
      default: function () {
        return {
          title: null,
          url: null,
          size: null,
          type: null
        }
      }
    }
  },
  components: {
    DocumentsList
  }
}
</script>
<style lang="scss" scoped>
.partner-info{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 1.875rem;
}
.img_block{
    position: relative;
    width: 2.82rem;
    height: 2.82rem;
    border-radius: 50%;
    background: url('../../assets/icons/document_icon.svg') center center no-repeat;
    background-size: 1rem;
    background-color: #eaf1f8;
}
.block_partner:last-child :last-child{
    margin-bottom: 0;
}

.documents__list{
  margin-bottom: 2rem;
}
.info_block{
    margin-left: 1rem;
    width: calc(100% - 1rem - 2.82rem);
    p{
        font-weight: 600;
        font-size: .88rem;
        line-height: 1.38rem;
        color: #1F3245;
    }
}
.download-file{
    margin-top: .97rem;
}
.news__pagination{
    padding-top: 2.5rem;
    border-top: .0625rem solid #D5D6D9;
}
@media screen and (max-width: 768px){
    .download-file{
        margin-top: .75rem;
    }
    .news__pagination{
        padding-top: 0;
        border-top: 0;
    }
}
</style>
