<template>
    <div>
        <bread-crumb :navigationArray="navigationLink"/>
        <div class="white-container">
            <h5>{{ $store.getters.GET_LANG ? 'Аппарат' : 'Apparatus' }}</h5>
            <div class="tabs">
                <p v-for="(tab, index) in tabs.list"
                   :class="{'active' : isActiveTab === index}"
                   :key="`tab${index}`" :id="`tab${index}`"
                   @click="setTab(index)"
                >{{ tab }}</p>
            </div>
            <!-- <partners-document-block :dataList="partnersData"/> -->
          <keep-alive>
            <component :is="tabs.pages[isActiveTab]" :content="content[isActiveTab]"/>
          </keep-alive>
        </div>
    </div>
</template>
<script>
import BreadCrumb from '@/components/BreadCrumb'
import ManagementDevice from '@/components/device/Management'
import DocumentsDevice from '@/components/device/Documents'
export default {
  name: 'DeviceChamber',
  components: {
    BreadCrumb,
    ManagementDevice,
    DocumentsDevice
  },
  data () {
    return {
      isActiveTab: 0,
      tabs: {
        list: [],
        pages: []
      },
      content: []
    }
  },
  methods: {
    setTab (index) {
      this.isActiveTab = index
    },
    async getData () {
      const res = await fetch(`${this.$store.getters.getUrlApi}api/apparat`, {
        headers: {
          Localization: this.$store.getters.GET_LANG ? 'ru' : 'eng'
        }
      })
      const data = await res.json()
      await data.data.map((item) => {
        if (item.title === 'Руководство' || item.title === 'Leader') {
          this.tabs.pages.push('ManagementDevice')
          this.tabs.list.push(item.title)
          this.content.push([])
        } else {
          this.tabs.pages.push('DocumentsDevice')
          this.tabs.list.push(item.title)
          this.content.push(item.documents)
        }
      })
    }
  },
  computed: {
    navigationLink () {
      return [
        {
          title: this.$store.getters.GET_LANG ? 'Главная' : 'Home',
          path: '/'
        },
        {
          title: this.$store.getters.GET_LANG ? 'Аппарат' : 'Apparatus'
        }
      ]
    }
  },
  mounted () {
    this.getData()
    if (this.$route.query.from) {
      this.isActiveTab = 1
    }
    document.title = 'Аппарат'
  }
}
</script>
<style lang="scss" scoped>
.white-container{
    width: 61.44rem;
    margin: 0 5rem;
    padding: 2.5rem 2.94rem;
}
h5{
    font-weight: 600;
    font-size: 1.38rem;
    line-height: 1.75rem;
    color: #1F3245;
}
.tabs{
    display: flex;
    border-radius: .125rem;
    border-bottom: .06rem solid #D5D6D9;
    margin: 2rem 0;

    p{
        font-size: .75rem;
        line-height: 1.125rem;
        color: #003E78;
        opacity: 0.6;
        margin-right: 1.25rem;
        padding-bottom: 1.375rem;
        cursor: pointer;
        transition: font-size .2s;
    }

    .active {
        font-weight: 600;
        font-size: .813rem;
        line-height: 1.25rem;
        color: #246CB7;
        border-bottom: .125rem solid #246CB7;
        padding-bottom: 1.25rem;
        margin-bottom: -0.07rem;
        margin-top: -0.15rem;
        transition: font-size .2s;
    }
}
@media screen and (max-width: 768px){
  .white-container{
    width: auto;
    margin: 0 .75rem;
    padding: 1.86rem 1.25rem;
  }
}
@media screen and (max-width: 420px){
    .white-container{
        width: 100%;
        margin: 0;
        padding: 1.88rem 1rem;
    }
    .tabs{
        overflow-x: auto;
        flex-wrap: nowrap;
        margin: 1.38rem -0.76rem 1.38rem 0;
        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none;
        p{
            flex: 0 0 auto;
            padding-bottom: 1.05rem;
            margin-right: 1rem;
        }
        > .active{
            margin-bottom: 0;
            padding-bottom: 1rem;
        }
    }
    #tab4{ padding-right: .76rem;}
    .tabs::-webkit-scrollbar { width: 0; height: 0;}
}
</style>
