<template>
    <section v-if="leader">
        <div class="img_content" :style="`background: url(${$store.getters.getUrlApi}${leader.photo}) center / cover`"></div>
        <div class="block_info">
            <h5>{{leader.fullName}}</h5>
            <p class="title-content">{{ $store.getters.GET_LANG ? 'Членство' : 'Membership' }}</p>
            <p v-for="(item, i) in leader.units[0].units" :key="`leader.units[0].units${i}}`" class="text-content">{{ item.name}}</p>
            <p class="title-content" v-if="leader.phone">{{ $store.getters.GET_LANG ? 'Телефон приемной' : 'Reception phone' }}</p>
            <a v-if="leader.phone" :href="`tel:${leader.phone}`">{{ leader.phone }}</a>
            <p class="title-content">{{ $store.getters.GET_LANG ? 'Биография' : 'Biography' }}</p>
            <p class="text-content ckEditor-html" v-html="leader.biography"></p>
            <router-link :to="linkButton" class="button-link">{{ $store.getters.GET_LANG ? 'Перейти в совет ОП РФ' : 'Go to the Council of the CC RF' }}</router-link>
        </div>
    </section>
</template>
<script>
export default {
  name: 'Management',
  data () {
    return {
      leader: null,
      linkButton: '/'
    }
  },
  methods: {
    dateContent (date) {
      return (new Date(date).toLocaleString('ru', { day: 'numeric', month: 'long', year: 'numeric' }).slice(0, -3))
    },
    async getContent () {
      let res = await fetch(`${this.$store.getters.getUrlApi}api/structure?convocation=${this.$store.getters.getConvocation[0].id}`, {
        headers: {
          Localization: this.$store.getters.GET_LANG ? 'ru' : 'eng'
        }
      })
      res = await res.json()
      res.data.every(item => {
        if (item.title.indexOf('Совет') !== -1) {
          this.linkButton = `/structure_list/${item.units[0].id}`
          return false
        }
      })
    }
  },
  async mounted () {
    await this.$store.dispatch('setConvocation')
    let res = await fetch(`${this.$store.getters.getUrlApi}api/member/ceo`, {
      headers: {
        Localization: this.$store.getters.GET_LANG ? 'ru' : 'eng'
      }
    })
    res = await res.json()
    this.leader = await res.data
    await this.getContent()
  }
}
</script>
<style lang="scss" scoped>
.ckEditor-html {
  ::v-deep {
    * {
      margin: revert;
      padding: revert;
      list-style-type: revert;
      font-size: revert;
      font-weight: revert;
    }
  }
}
section{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}
.block_info{
    display: flex;
    flex-direction: column;
    width: 33.5rem;
}
img{
    width: 12.13rem;
}
.img_content{
    width: 12.145rem;
    height: 15.0625rem;
    margin-right: 1.88rem;
    border-radius: .5rem;
}
h5{
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: rgba(23, 23, 23, 0.8);
}
.title-content{
    margin-top: 1.25rem;
    margin-bottom: .5rem;
    font-weight: 600;
    font-size: .88rem;
    line-height: 1.38rem;
    color: #1F3245;
}
a{
    font-size: .88rem;
    line-height: 1.38rem;
    color: #246CB7;
}
.text-content{
    font-size: .88rem;
    line-height: 1.38rem;
    color: rgba(23, 23, 23, 0.8);
    margin-bottom: 1.25rem;
}
.button-link{
    width: fit-content;
    font-weight: bold;
    font-size: .88rem;
    line-height: 1.25rem;
    color: #FFFFFF;
    background: #246CB7;
    border-radius: .19rem;
    padding: .5rem 2.125rem .44rem 2.125rem;
}
.date-content{
  margin-top: .75rem;
  font-size: .87rem;
  line-height: 1.375rem;
  color: #5E686C;
}
@media screen and (max-width: 768px){
  .img_content{
    width: 10.79rem;
    height: 12.59rem;
    margin-right: 1.24rem;
  }
}
@media screen and (max-width: 420px){
  section{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }
  .img_content {
    width: 8.8rem;
    height: 10.82rem;
    margin-bottom: 1.4rem;
  }
  .date-content{
    margin-top: .5rem;
  }
  .block_info{
    width: 100%;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .button-link {
        width: 15rem;
    }
}

</style>
